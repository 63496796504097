<template>
	<div class="caseInfo">
		<p class="lh28" v-html="data.desc"></p>
		<div class="caseInfo__additionalData">
			<a :href="data.linkHref" class="redText" target="_blank" v-html="data.linkText" v-if="data.linkText"></a>
			<p v-html="data.date" v-if="data.date"></p>
			<p v-for="info in data.dateInfo" v-if="data.dateInfo" v-html="info"></p>
		</div>
		<div class="caseInfo__info" v-if="data.info">
			<div v-for="info in data.info">
				<div class="caseInfo__info__content">
					<p class="caseInfo__info--title" v-html="info.title"></p>
					<p class="caseInfo__info--desc" v-html="info.desc"></p>
				</div>
				<div class="caseInfo__info__author">
					<img v-lazy="info.author.image">
					<div class="caseInfo__info__authorContent">
						<p class="caseInfo__info__author--name" v-html="info.author.name"></p>
						<p class="caseInfo__info__author--desc" v-html="info.author.desc"></p>
					</div>
				</div>
			</div>
		</div>		
	</div>
</template>


<style lang="sass">
	@import '@/assets/sass/case/CaseInfo'
</style>

<script>
	export default {
		props: ['data'],
	}
</script>
